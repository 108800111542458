import React, { useState } from "react";
import styled from "styled-components";
import { rem, vwDesktop, vwMobile } from "../../helpers/styles";
import { MEDIA_DESKTOP } from "../../helpers/theme";
import { Image } from "../../types";
import SearchInput from "../RecipeSearch/TextInput";
import Link from "../shared/Link";
import TextLink from "../shared/TextLink";
import { ROUTES } from "../../helpers/routes";
import { getLink, openLink } from "../../helpers/link";
import useRightToLeft from "../../hooks/useRightToLeft";

export type HeroProps = {
  title1: string;
  title2: string;
  searchPlaceholder: string;
  tagsLabel: string;
  image: Image;
  recipeSocialMedia: string;
  recipeSocialMediaLink: string;
  recipeSubtitle: string;
  recipeTitle: string;
  allRecipes: string;
  tags: Array<{ label: string; link: string }>;
};
export function Hero(props: HeroProps): JSX.Element {
  const [search, setSearch] = useState("");
  const isRTL = useRightToLeft();

  return (
    <Container>
      <div>
        <Title dangerouslySetInnerHTML={{ __html: props.title1 }} />

        <InputBlock>
          <SearchInput
            value={search}
            onChange={(v) => setSearch(v as string)}
            label={props.searchPlaceholder}
            onClose={() => {
              setSearch("");
            }}
            onKeyPress={(e) => {
              if (e.key !== "Enter") {
                return;
              }
              openLink(getLink(`${ROUTES.RECIPES_SEARCH}?q=${search}`));
            }}
          />
        </InputBlock>

        <JumpToLabel>{props.tagsLabel}</JumpToLabel>

        <Tags>
          {props.tags?.map((tag) => (
            <a key={tag?.link} href={tag?.link}>
              {tag.label}
            </a>
          ))}
        </Tags>

        {props?.allRecipes && (
          <TextLinkContainer>
            <TextLink
              isRTL={isRTL}
              color="#75c154"
              link={`${ROUTES.RECIPES_SEARCH}?q=`}
              label={props.allRecipes}
            />
          </TextLinkContainer>
        )}
      </div>
      <div>
        <img src={props.image?.src} alt={props.image?.alt} />
        <FeaturedCard
          to={props.recipeSocialMediaLink}
          aria-label={props.recipeTitle}
        >
          <Subtitle>{props.recipeSubtitle}</Subtitle>
          <FeaturedTitle>{props.recipeTitle}</FeaturedTitle>
          <SocialMedia>{props.recipeSocialMedia}</SocialMedia>
        </FeaturedCard>
      </div>
    </Container>
  );
}

export default Hero;

const FeaturedCard = styled(Link)`
  position: absolute;
  left: 50%;
  bottom: 0;
  width: ${vwMobile(337)};
  padding: ${vwMobile(24)};
  transform: translate(-50%, ${vwMobile(107)});

  color: black;
  background-color: white;
  text-transform: uppercase;
  text-decoration: none;

  ${MEDIA_DESKTOP} {
    width: ${vwDesktop(456)};
    transform: translate(-50%, ${vwDesktop(53)});
    padding: ${vwDesktop(28)} ${vwDesktop(24)} ${vwDesktop(16)};
  }
`;

const SocialMedia = styled.div`
  color: #7a3818;
  font-size: ${rem(18)};
  font-weight: 800;
  letter-spacing: 0.08em;
  line-height: ${vwMobile(24)};
  text-decoration: none;

  ${MEDIA_DESKTOP} {
    line-height: ${vwDesktop(24)};
  }
`;

const Subtitle = styled.h2`
  color: #012102;
  margin-bottom: ${vwMobile(4)};
  letter-spacing: 0.08em;
  line-height: ${vwMobile(24)};
  font-size: ${rem(18)};
  font-weight: 800;

  ${MEDIA_DESKTOP} {
    margin-bottom: ${vwDesktop(4)};
    line-height: ${vwDesktop(24)};
  }
`;

const FeaturedTitle = styled.h3`
  font-size: ${rem(24)};
  font-weight: 900;
  letter-spacing: 0.04em;
  line-height: ${vwMobile(31)};
  margin-bottom: ${vwMobile(16)};

  ${MEDIA_DESKTOP} {
    font-size: ${rem(32)};
    letter-spacing: 0.02em;
    line-height: ${vwDesktop(42)};
    margin-bottom: ${vwDesktop(23)};
  }
`;

const InputBlock = styled.div`
  margin-bottom: ${vwMobile(40)};
  ${MEDIA_DESKTOP} {
    margin-bottom: ${vwDesktop(45)};
  }
`;

const JumpToLabel = styled.p`
  margin-bottom: ${vwMobile(21)};

  font-size: ${rem(24)};
  font-weight: 900;
  letter-spacing: 0.06em;
  line-height: ${vwMobile(31)};
  color: #ffffff;
  text-transform: uppercase;

  ${MEDIA_DESKTOP} {
    margin-bottom: ${vwDesktop(21)};
    line-height: ${vwDesktop(31)};
  }
`;

const Tags = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: ${vwMobile(12)};

  ${MEDIA_DESKTOP} {
    gap: ${vwDesktop(12)};
  }

  a {
    padding: ${vwMobile(14)} ${vwMobile(16)} ${vwMobile(11)};
    border: ${vwMobile(1)} solid #75c154;

    font-size: ${rem(18)};
    font-weight: 800;
    line-height: ${vwMobile(28)};
    color: white;
    flex: 0 0 auto;
    text-decoration: none;
    transition: all 0.3s;

    &:hover {
      transform: scale(0.95);
    }

    ${MEDIA_DESKTOP} {
      border-width: ${vwDesktop(1)};
      padding: ${vwDesktop(14)} ${vwDesktop(16)} ${vwDesktop(11)};
      line-height: ${vwDesktop(28)};
    }
  }
`;

const Title = styled.h1`
  font-weight: 900;
  color: #ffffff;
  text-transform: uppercase;
  font-size: ${rem(48)};
  line-height: ${rem(52)};
  letter-spacing: 0.08em;
  margin-bottom: ${vwMobile(23)};

  strong {
    color: #75c154;
    font-weight: inherit;
  }

  ${MEDIA_DESKTOP} {
    font-size: ${rem(80)};
    line-height: ${rem(88)};
    margin-bottom: ${vwDesktop(38)};
  }
`;

const Container = styled.section`
  display: flex;
  flex-direction: column-reverse;
  color: #ffffff;
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  background-color: #033305;

  ${MEDIA_DESKTOP} {
    gap: ${vwDesktop(71)};
    flex-direction: row;
    align-items: flex-start;
    padding: 0 ${vwDesktop(100)} ${vwDesktop(133)} ${vwDesktop(205)};
  }

  > div:first-child {
    padding: 0 ${vwMobile(24)} ${vwMobile(40)};
    ${MEDIA_DESKTOP} {
      padding: ${vwDesktop(80)} 0 0 0;
      width: ${vwDesktop(454)};
    }
  }

  > div:last-child {
    height: ${vwMobile(390)};
    position: relative;
    margin-bottom: ${vwMobile(147)};
    ${MEDIA_DESKTOP} {
      margin-bottom: ${vwDesktop(0)};
      width: ${vwDesktop(610)};
      height: ${vwDesktop(570)};
    }
    img {
      width: 100%;
      height: 100%;
      z-index: 1;
      object-fit: cover;
    }
  }
`;

const TextLinkContainer = styled.div`
  margin: ${vwMobile(20)} 0;

  ${MEDIA_DESKTOP} {
    margin: ${vwDesktop(20)} 0;
  }
`;
