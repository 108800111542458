import React from "react";
import styled from "styled-components";
import { rem, vwDesktop, vwMobile } from "../../helpers/styles";
import { MEDIA_DESKTOP } from "../../helpers/theme";
import { ButtonType } from "../shared/Button";
import ButtonLink from "../shared/ButtonLink";
import Link from "../shared/Link";
import { Image } from "../../types";

export type ShareYourRecipeProps = {
  title: string;
  description: string;
  buttonLabel: string;
  buttonLink: string;

  image1: Image;
  image2: Image;

  socialMediaUser: string;
  socialMediaOpinion: string;
  socialMediaLink: string;
};

export function ShareYourRecipe(props: ShareYourRecipeProps): JSX.Element {
  return (
    <Container>
      <Featured>
        <img src={props.image1?.src} alt={props.image1?.alt} />
        <div className="card">
          <img src={props.image2?.src} alt={props.image2?.alt} />
          <div className="title">{props.socialMediaOpinion}</div>
          <SocialMedia to={props.socialMediaLink ?? ""}>
            {props.socialMediaUser}
          </SocialMedia>
        </div>
      </Featured>
      <Message>
        <h2 className="title">{props.title}</h2>
        <p className="description">{props.description}</p>
        <ButtonLink
          className="button"
          link={props.buttonLink}
          label={props.buttonLabel}
          type={ButtonType.PRIMARY_DARK}
        />
      </Message>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${vwMobile(40)};
  background-color: #678e8b;
  padding: ${vwMobile(40)} ${vwMobile(19)};

  font-family: ${props => props.theme.fontFamily}, ${props => props.theme.fallBackFontFamily}, sans-serif;

  ${MEDIA_DESKTOP} {
    flex-direction: row-reverse;
    gap: ${vwDesktop(145)};
    padding: ${vwDesktop(80)} ${vwDesktop(185)} ${vwDesktop(80)}
      ${vwDesktop(205)};
  }
`;

const Featured = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: ${vwMobile(338)};
    height: ${vwMobile(338)};
    margin-bottom: ${vwMobile(0)};
    object-fit: cover;
    ${MEDIA_DESKTOP} {
      width: ${vwDesktop(505)};
      height: ${vwDesktop(505)};
      margin-bottom: ${vwDesktop(46)};
    }
  }

  .card {
    overflow: hidden;
    font-size: ${rem(18)};
    text-transform: uppercase;
    width: 100%;

    ${MEDIA_DESKTOP} {
      width: ${vwDesktop(630)};
      margin-left: ${vwDesktop(-125)};
      overflow: visible;
    }

    img {
      width: ${vwMobile(99)};
      height: ${vwMobile(100)};
      margin-right: ${vwMobile(12)};
      float: left;
      object-fit: cover;

      ${MEDIA_DESKTOP} {
        width: ${vwDesktop(210)};
        height: ${vwDesktop(210)};
        margin-right: ${vwDesktop(20)};
        margin-top: ${vwDesktop(-112)};
      }
    }

    .title {
      color: white;
      font-weight: 900;
      letter-spacing: 0.08em;
      margin-bottom: ${vwMobile(13)};
      line-height: ${rem(24)};

      ${MEDIA_DESKTOP} {
        margin-bottom: ${vwDesktop(12)};
        letter-spacing: 0.06em;
        font-size: ${rem(24)};
        line-height: ${rem(31)};
      }
    }
  }
`;

const SocialMedia = styled(Link)`
  color: #162221;
  font-weight: 800;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const Message = styled.div`
  line-height: ${rem(28)};
  color: #182624;

  .title {
    font-size: ${rem(44)};
    line-height: ${rem(53)};
    font-weight: 900;
    letter-spacing: 0.012em;
    margin-bottom: ${vwMobile(10)};
    text-transform: uppercase;

    ${MEDIA_DESKTOP} {
      font-size: ${rem(60)};
      line-height: ${rem(64)};
      letter-spacing: 0.03em;
      margin-top: ${vwDesktop(172)};
      margin-bottom: ${vwDesktop(8)};
    }
  }

  .description {
    color: #162221;
    font-size: ${rem(18)};
    line-height: ${rem(28)};
    letter-spacing: 0;
    margin-bottom: ${vwMobile(29)};
    font-weight: 600;
    ${MEDIA_DESKTOP} {
      margin-bottom: ${vwDesktop(41)};
    }
  }
`;
