import { uniqueId } from "lodash";
import React, { useState, useEffect } from "react";
import ScrollContainer from "react-indiana-drag-scroll";
import styled from "styled-components";
import { snakeCase } from "lodash";
import { rem, vwDesktop, vwMobile } from "../../helpers/styles";
import { MEDIA_DESKTOP } from "../../helpers/theme";
import RecipeCard, { RecipeItem } from "../shared/RecipeCard";
import TextLink from "../shared/TextLink";
import { ROUTES } from "../../helpers/routes";
import useRightToLeft from "../../hooks/useRightToLeft";
import { useIntl } from "../../hooks/useIntl";

export type RecipesSectionProps = {
  isPublished?: boolean;
  allRecipesLabel?: string;
  link?: link;
  searchKeyword?: string;
  title: string;
  type?: string;
  tags?: [];
  recipeList: RecipeItem[];
  recipesList?: RecipeItem[];
} & RecipeSectionColors;

export type RecipeSectionColors = {
  bgColor?: string;
  headerColor?: string;
  itemBgColor: string;
  itemTextColor: string;
  itemLabelColor: string;
  itemHoverBgColor: string;
  itemHoverTextColor: string;
};

type link = {
  label: string;
  url: string;
};

export function RecipesSection(props: RecipesSectionProps): JSX.Element {
  const {
    headerColor,
    bgColor,
    recipeList = [],
    itemBgColor,
    itemHoverBgColor,
    itemHoverTextColor,
    searchKeyword: search_keyword,
  } = props;
  const recipeId = uniqueId("recipe-item-");

  const [tags, setTags] = useState();
  const intl = useIntl();

  useEffect(() => {
    const tagsNames = props?.tags
      ?.map((item) => item.recipe_tag_id.translations)
      .reduce((acc, translations) => acc.concat(translations), [])
      .filter((translation) => translation.languages_code.code === intl.locale)
      .map((translation) => translation.label)
      .join("-");
    setTags(tagsNames);
  }, []);

  if (recipeList?.length === 0) return null;

  if (props?.isPublished === false) return null;

  const isRTL = useRightToLeft();

  return (
    <Container id={snakeCase(search_keyword)} {...{ headerColor, bgColor }}>
      <header>
        <Title>
          <h2 dangerouslySetInnerHTML={{ __html: props.title }} />
        </Title>
        {props.tags?.length > 0 ? (
          <TextLink
            isRTL={isRTL}
            color={headerColor}
            link={`${ROUTES.RECIPES_SEARCH}?q=tags-${tags}`}
            label={props.link ? props.link?.label : props.allRecipesLabel}
          />
        ) : (
          (props.searchKeyword || props.link?.url) && (
            <TextLink
              isRTL={isRTL}
              color={headerColor}
              link={
                props.link?.url
                  ? `${props.link?.url}`
                  : `${ROUTES.RECIPES_SEARCH}?q=${search_keyword || ""}`
              }
              label={props.link ? props.link?.label : props.allRecipesLabel}
            />
          )
        )}
      </header>

      <StyledScrollContainer vertical={false}>
        <ItemsTrack>
          {recipeList
            .filter((recipe) => !!recipe)
            .map((recipe, i) => (
              <RecipeCard
                key={`${recipeId}-${i}`}
                bgColor={itemBgColor}
                textColor={props.itemTextColor}
                labelColor={props.itemLabelColor}
                content={recipe}
                hoverBgColor={itemHoverBgColor}
                hoverTextColor={itemHoverTextColor}
              />
            ))}
        </ItemsTrack>
      </StyledScrollContainer>
    </Container>
  );
}
export default RecipesSection;

const ItemsTrack = styled.div`
  display: flex;
  gap: ${vwMobile(8)};

  ${MEDIA_DESKTOP} {
    gap: ${vwDesktop(20)};
  }
`;

const StyledScrollContainer = styled(ScrollContainer)`
  overflow-x: auto !important;
`;

const Container = styled.section<{ bgColor?: string; headerColor?: string }>`
  padding: ${vwMobile(51)} 0 ${vwMobile(40)};
  background-color: ${(p) => p.bgColor ?? "transparent"};

  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;

  ${MEDIA_DESKTOP} {
    padding: ${(props) =>
      props.theme.isRTL
        ? `${vwDesktop(96)} ${vwDesktop(100)} ${vwDesktop(91)} 0`
        : `${vwDesktop(96)} 0 ${vwDesktop(91)} ${vwDesktop(100)}`};
  }

  > header {
    padding-inline: ${vwMobile(20)};
    margin-bottom: ${vwMobile(41)};
    display: flex;
    flex-direction: column;
    gap: ${vwMobile(25)};
    color: ${(p) => p.headerColor ?? "white"};

    ${MEDIA_DESKTOP} {
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
      margin-bottom: ${vwDesktop(83)};
      padding: ${(props) =>
        props.theme.isRTL
          ? `0 0 0 ${vwDesktop(102)}`
          : `0 ${vwDesktop(102)} 0 0`};
    }
  }
`;

const Title = styled.div`
  > * {
    font-size: ${rem(44)};
    font-weight: 900;
    letter-spacing: 0.012em;
    line-height: ${rem(53)};
    text-transform: uppercase;

    ${MEDIA_DESKTOP} {
      width: ${vwDesktop(814)};
      letter-spacing: 0.03em;
      line-height: ${rem(64)};
      font-size: ${rem(60)};
    }
  }
`;
